<template>
  <div class="dropdown lang-dropdown ml-2 mr-3">
    <button
      class="btn text-white p-0 dropdown-toggle"
      type="button"
      id="dropdownLanguages"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      @click="addCountries"
    >
      <img
        class="img-fluid border rounded"
        :src="getImgUrl(currentLanguageImg($i18n.locale))"
        :alt="currentLanguageAltText($i18n.locale)"
      />
    </button>
    <div
      class="dropdown-menu langSwitcher-dropdownMenu p-1"
      aria-labelledby="dropdownLanguages"
    >
      <button
        class="dropdown-item d-flex align-items-center p-1"
        type="button"
        v-for="flag in flags"
        :key="flag"
        :data-language="flag.language"
        @click.stop="changeLanguageURL($event)"
      >
        <img
          class="img-fluid border rounded"
          :src="getImgUrl(flag.imgSrc)"
          :alt="flag.alt"
        />
        <span class="pl-2">{{ flag.country }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import router from "../router";
import i18n from "../i18n";

export default {
  name: "LanguageSwitcher",
  data() {
    return {
      flags: [
        {
          language: "en",
          imgSrc: "img/flags/english.jpg",
          alt: "English flag",
        },
        {
          language: "ro",
          imgSrc: "img/flags/romanian.jpg",
          alt: "Romanian flag",
        },
        {
          language: "de",
          imgSrc: "img/flags/german.jpg",
          alt: "German flag",
        },
        {
          language: "bg",
          imgSrc: "img/flags/bulgarian.jpg",
          alt: "Bulgarian flag",
        },
        {
          language: "fr",
          imgSrc: "img/flags/french.jpg",
          alt: "French flag",
        },
        {
          language: "it",
          imgSrc: "img/flags/italian.jpg",
          alt: "Italian flag",
        },
        {
          language: "pl",
          imgSrc: "img/flags/polish.jpg",
          alt: "Polish flag",
        },
        {
          language: "es",
          imgSrc: "img/flags/spanish.jpg",
          alt: "Spanish flag",
        },
        {
          language: "cz",
          imgSrc: "img/flags/czech.jpg",
          alt: "Czech flag",
        },
        {
          language: "gr",
          imgSrc: "img/flags/greece.jpg",
          alt: "Greek flag",
        },
        {
          language: "hr",
          imgSrc: "img/flags/croatian.jpg",
          alt: "Croatian flag",
        },
        {
          language: "pt",
          imgSrc: "img/flags/portugal.jpg",
          alt: "Portuguese flag",
        },
        {
          language: "sk",
          imgSrc: "img/flags/slovakia.jpg",
          alt: "Slovak flag",
        },
        {
          language: "hu",
          imgSrc: "img/flags/hungary.jpg",
          alt: "Hungarian flag",
        },
        {
          language: "sw",
          imgSrc: "img/flags/sweden.jpg",
          alt: "Sweden flag",
        },
        {
          language: "nl",
          imgSrc: "img/flags/netherlands.jpg",
          alt: "Netherlands flag",
        },

        {
          language: "dk",
          imgSrc: "img/flags/denmark.jpg",
          alt: "Danish flag",
        },
        {
          language: "fi",
          imgSrc: "img/flags/finland.jpg",
          alt: "Finnish flag",
        },
        {
          language: "si",
          imgSrc: "img/flags/slovenian.jpg",
          alt: "Slovenian flag",
        },
      ],
    };
  },

  methods: {
    getImgUrl(img) {
      return require("../assets/" + img);
    },

    changeLanguageURL(event) {
      let elem = event.target;
      if (elem.nodeName === "BUTTON") {
        elem = elem.firstElementChild;
      }

      elem.parentElement.parentElement.classList.remove("show");

      let dropdownBtn = elem.parentElement.parentElement.parentElement;
      let ariaExpanded = dropdownBtn
        .querySelector("#dropdownLanguages")
        .getAttribute("aria-expanded");
      if (ariaExpanded === "true") {
        ariaExpanded = "false";
      } else {
        ariaExpanded = "true";
      }
      dropdownBtn
        .querySelector("#dropdownLanguages")
        .setAttribute("aria-expanded", ariaExpanded);

      elem.parentElement.parentElement.parentElement.classList.remove("show");

      const el = elem.parentElement;
      this.$i18n.locale = el.dataset.language;
      let currentURL = router.currentRoute.value.fullPath;
      let languageExist = currentURL.includes(i18n.locale);
      if (languageExist) {
        let foundLanguage = currentURL.replace(
          i18n.locale,
          el.dataset.language
        );
        localStorage.setItem("language", el.dataset.language);
        router.push(foundLanguage);
      }
    },

    currentLanguageImg(language) {
      let currentLangForURL = "";
      const totalLanguages = this.flags.length;

      for (let i = 0; i < totalLanguages; i++) {
        if (this.flags[i].language === language) {
          currentLangForURL = this.flags[i].imgSrc;
          break;
        }
      }
      return currentLangForURL;
    },

    currentLanguageAltText(language) {
      let currentLangAltText = "";
      const totalLanguages = this.flags.length;

      for (let i = 0; i < totalLanguages; i++) {
        if (this.flags[i].language === language) {
          currentLangAltText = this.flags[i].alt;
          break;
        }
      }
      return currentLangAltText;
    },

    addCountries() {
      const totalLanguages = this.flags.length;
      for (let i = 0; i < totalLanguages; i++) {
        this.flags[i].country = this.flags[i].alt.slice(
          0,
          this.flags[i].alt.length - 5
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.lang-dropdown,
.dropdown-item img {
  width: 34px;
  line-height: 1;
}

.lang-dropdown button {
  line-height: 1;
}

/*.langSwitcher-dropdownMenu {*/
/*min-width: max-content;*/
/*}*/

.d-lg-none .lang-dropdown .dropdown-menu.langSwitcher-dropdownMenu {
  left: unset;
  right: 0;
}

.dropdown-menu.show {
  display: flex;
  flex-wrap: wrap;
  width: max-content;
  max-width: 300px;
  @media (max-width: 384px) {
    font-size: 0.8rem;
    max-width: 235px;
  }
}

.dropdown-menu.show > .dropdown-item {
  flex: 50%;
}
</style>
