import { createI18n } from 'vue-i18n'

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default
    }
  });
  return messages
}

let storageLanguage = localStorage.getItem('language');
let currentURL = window.location.pathname;
// console.log('window.location.pathname:', currentURL);
let newURL = currentURL.split("/");
// console.log('newURL:', newURL);
let availableLang = process.env.VUE_APP_I18N_LOCALE;
// console.log('process.env.VUE_APP_I18N_LOCALE:', availableLang);
newURL = newURL[1];
// console.log('newURL[1]:', newURL);
// console.log('newURL length:', newURL.length);

// console.log(newURL);
let isAvailable = availableLang.split(",").includes(newURL);
// console.log(availableLang.split(","));
// console.log(availableLang.split(",").includes(newURL));
// console.log(isAvailable);

if ( newURL.length < 2 || !isAvailable) {
  newURL = null;
}
// console.log(newURL);

//
// if (!isAvailable) {
//   newURL = null;
// }

export default createI18n({
  locale: newURL || storageLanguage || "ro",
  // locale: newURL || "ro",
  // fallbackLocale: storageLanguage || "ro",
  fallbackLocale: "en",
  messages: loadLocaleMessages()
})


