<template>
    <div>
        <LoggedInHeader v-if="$store.getters.isLoggedIn"/>
        <keep-alive v-else>
            <TheHeader/>
        </keep-alive>

        <router-view :key="$route.path" :products="products" @brandActive="brandActive"/>

        <keep-alive v-if="!$store.getters.isLoggedIn">
            <TheFooter/>
        </keep-alive>
    </div>
</template>

<script>
    import TheHeader from '@/components/TheHeader.vue';
    import TheFooter from '@/components/TheFooter.vue';
    import LoggedInHeader from "@/components/LoggedInHeader.vue";
    // import productsData from './products.js'
    import i18n from './i18n'
    import productsData from './prod-data.json'

    export default {
        name: 'App',
        data() {
            return {
                products: productsData
            };
        },
        components: {
            LoggedInHeader,
            TheHeader,
            TheFooter,
        },
        methods: {
            brandActive() {
                // Preia brandul din URL
                let detectedBrand = window.location.pathname;
                let allBrands = this.products;
                const allBrandsLength = allBrands.length;

                for (let i = 0; i < allBrandsLength; i++) {
                    this.products[i].isActive = `/${i18n.locale}/brands/` + allBrands[i].brand === detectedBrand; // set to true or false
                }
            }
        }
    }
</script>

<style lang="scss">
    @import './assets/scss/main.scss';
</style>
