<template>
    <header class="d-flex px-4 py-2 text-white align-items-center position-fixed">
        <img class="mr-auto" src="@/assets/img/userLoggedIn/koff-logo1.png" alt="koff logo">
        <span class="mr-3">{{ username }}</span>
        <input type="button" value="Logout" @click="logout"/>
    </header>
</template>

<script>
    import i18n from '../i18n.js';

    export default {
        name: "LoggedInHeader",
        data() {
            return {
                username: ''
            };
        },
        async created() {
            if (!this.$store.getters.isLoggedIn) {
                this.$router.push(`/${i18n.locale}/login`);
            }

            this.username = this.$store.getters.getUser.username;
        },
        methods: {
            logout() {
                this.$store.dispatch('logout');
                this.$router.push(`/${i18n.locale}/login`);
            }
        }
    }
</script>

<style scoped>
    header.position-fixed {
        left: 0;
        top: 0;
        right: 0;
        z-index: 3;
    }
</style>