<template>
    <div class="home">
        <div class="page-banner">
            <img src="../assets/img/banner-1.jpg" class="img-responsive" id="index-banner"
                 alt="banner koff distribution">
        </div>

        <section id="new-products">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h3 class="section-title center">{{ $t('home.title') }}</h3>
                        <div class="showcase">
                            <div class="product">
                                <div class="product-container">
                                    <img src="../assets/img/new-1.jpg" alt="Incarcator wireless koff"
                                         class="img-responsive">
                                    <div class="product-details">
                                        <h3 class="product-category">{{ $t('home.card-1.product-category').toUpperCase()
                                            }}</h3>
                                        <p class="product-name">{{ $t('home.card-1.product-name').toUpperCase() }}</p>
                                    </div>
                                </div>
                                <router-link :hreflang="`${$i18n.locale}`" :to="`/${$i18n.locale}/brands/techsuit`"
                                             class="product-category-link center">{{
                                    $t('home.card-1.category-link').toUpperCase() }}
                                </router-link>
                            </div>
                            <div class="product">
                                <div class="product-container">
                                    <img src="../assets/img/new-2.jpg" alt="Incarcator auto masina"
                                         class="img-responsive">
                                    <div class="product-details">
                                        <h3 class="product-category">{{ $t('home.card-2.product-category').toUpperCase()
                                            }}</h3>
                                        <p class="product-name">{{ $t('home.card-2.product-name').toUpperCase() }}</p>
                                    </div>
                                </div>
                                <router-link :hreflang="`${$i18n.locale}`" :to="`/${$i18n.locale}/brands/techsuit`"
                                             class="product-category-link center">{{
                                    $t('home.card-2.category-link').toUpperCase() }}
                                </router-link>
                            </div>
                            <div class="product">
                                <div class="product-container">
                                    <img src="../assets/img/new-3.jpg" alt="Incarcator auto masina"
                                         class="img-responsive">
                                    <div class="product-details">
                                        <h3 class="product-category">{{ $t('home.card-3.product-category').toUpperCase()
                                            }}</h3>
                                        <p class="product-name">{{ $t('home.card-3.product-name').toUpperCase() }}</p>
                                    </div>
                                </div>
                                <router-link :hreflang="`${$i18n.locale}`" :to="`/${$i18n.locale}/brands/techsuit`"
                                             class="product-category-link center">{{
                                    $t('home.card-3.category-link').toUpperCase() }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="popular-series">
            <div class="container" v-cloak>
                <div class="row">
                    <div class="col-md-12">
                        <h3 class="section-title center">{{ $t('home.popular-series.title') }}</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <ul id="tabs" class="nav nav-tabs">
                            <li class="nav-item" v-for="(product, index) in homeProducts" :key="index">
                                <a class="nav-link small text-uppercase"
                                   :class="{ active: product.brand === selectedBrand }"
                                   :data-target="'#' + product.brand"
                                   data-toggle="tab"> {{ product.brand }} </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div id="tabsContent" class="tab-content">
                            <div class="tab-pane fade" v-for="(product, index) in homeProducts" :key="index"
                                 :id="product.brand"
                                 :class="{ 'active show' : product.brand === selectedBrand }">
                                <div class="mproducts">
                                    <template v-for="(model, index) in product.models" :key="index">
                                        <!--<router-link :hreflang="`${$i18n.locale}`" :to="{-->
                                                        <!--name: 'Serie',-->
                                                        <!--params: {-->
                                                            <!--lang: $i18n.locale,-->
                                                            <!--brand: product.brand,-->
                                                            <!--serie: model.pathName-->
                                                        <!--}-->
                                                    <!--}"-->
                                                     <!--v-if="model.isPopular" href=""-->
                                                     <!--class="mp-item">-->
                                        <!-- dezactivat temporar link-ul -->
                                        <a href="#" v-if="model.isPopular" class="mp-item">
                                            <img class="img-responsive" :src="require('@/assets/' + model.imgSource)"
                                                 :alt="model.name + ' picture'">
                                            <p>{{ model.name }}</p>
                                        </a>
                                        <!--</router-link>-->
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <router-link :hreflang="`${$i18n.locale}`" :to="{
                                        name: 'Brands',
                                        params: {
                                            lang: $i18n.locale,
                                            brand: homeProducts[0].brand
                                        }
                                    }" id="more-products" class="btn btn-black">
                            {{ $t('home.more-products') }} <span id="changeBrand">TechSuit</span></router-link>
                    </div>
                </div>
            </div>
        </section>

        <section id="becomepartner">
            <div class="container">
                <div class="row">
                    <div class="col-md-7 d-flex">
                        <div id="partner-text">
                            <h3>{{ $t('home.partner.title') }}</h3>
                            <p>{{ $t('home.partner.content') }}</p>
                            <a href="https://shop.koff.ro/" target="_blank" class="btn btn-blue ripple">{{
                                $t('home.partner.register-button') }}</a>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div id="partner-img">
                            <img src="../assets/img/img-partners.jpg" class="img-responsive" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    // import productsData from '../products'

    export default {
        created() {
            this.popularProducts();
        },
        props: ['products'],
        data() {
            return {
                homeProducts: '',
                selectedBrand: ''
            }
        },
        methods: {
            popularProducts() {
                this.homeProducts = this.products.filter(product => {
                    return product.isPopular;
                });
                this.selectedBrand = this.homeProducts[0].brand;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../assets/scss/main.scss';

    #popular-series {
        .mproducts {
            flex-wrap: nowrap !important;
            -ms-overflow-style: none;
            scrollbar-height: none;
            overflow-x: scroll!important;
            /*justify-content: center;*/
            justify-content: unset !important;

            .mp-item {
                min-width: unset !important;
                width: unset !important;

                &:first-child {
                    margin-left: auto;
                }
                &:last-child {
                    margin-right: auto;
                }

                .img-responsive {
                    max-width: unset!important;
                }
            }
        }

        #tabs {
            -ms-overflow-style: none;
            scrollbar-height: none;
            overflow-x: scroll;
            justify-content: unset !important;

            .nav-item {
                &:first-child {
                    margin-left: auto;
                    border-right: 1px solid #dddddd;
                }
                &:last-child {
                    margin-right: auto;
                }
            }
        }

        #tabs::-webkit-scrollbar,
        .mproducts::-webkit-scrollbar {
            display: none;
        }

        a.nav-link {
            min-width: 100px!important;
            width: unset!important;
            cursor: pointer;
        }

        .nav-tabs {
            justify-content: center !important;
        }
    }

    #becomepartner {
        background: #fff;
    }
</style>
