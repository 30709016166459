import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import { h, resolveComponent } from "vue";
import i18n from "../i18n";
const { t } = i18n.global;

export const routes = [
  {
    path: "/contact",
    redirect: "/en/contact",
  },
  {
    path: "/",
    redirect: `/${i18n.locale}`,
  },
  {
    path: "/:lang",
    component: {
      render() {
        return h(resolveComponent("router-view"));
      },
    },
    meta: {
      title: t("meta.title"),
      metaTags: [
        {
          name: "description",
          content: t("meta.description"),
        },
        {
          property: "og:description",
          content: t("meta.og-description"),
        },
        {
          property: "og:title",
          content: t("meta.og-title"),
        },
      ],
    },
    children: [
      {
        path: "",
        name: "Home",
        component: Home,
      },
      {
        path: "about-us",
        name: "About",
        component: () =>
          import(/* webpackChunkName: 'about' */ "@/views/About.vue"),
      },
      {
        path: "contact",
        name: "Contact",
        component: () =>
          import(/* webpackChunkName: 'contact' */ "@/views/Contact.vue"),
      },
      {
        path: "delivery",
        name: "Delivery",
        component: () =>
          import(/* webpackChunkName: 'delivery' */ "@/views/Delivery.vue"),
      },
      {
        path: "cookie-policy",
        name: "Cookie-policy",
        component: () =>
          import(/* webpackChunkName: 'cookie' */ "@/views/Cookie-policy.vue"),
      },
      {
        path: "privacy-policy",
        name: "Privacy-policy",
        component: () =>
          import(
            /* webpackChunkName: 'privacy' */ "@/views/Privacy-policy.vue"
          ),
      },
      {
        path: "brands/:brand",
        name: "Brands",
        component: () =>
          import(/* webpackChunkName: 'brands' */ "@/views/Brands.vue"),
        meta: {
          title: "",
          metaTags: [
            {
              name: "description",
              content: "",
            },
          ],
        },
      },
      {
        path: "brands/:brand/:serie",
        name: "Serie",
        component: () =>
          import(/* webpackChunkName: 'serie' */ "@/views/Serie.vue"),
      },
      // Dragos: vezi ca am comentat eu login/register
      // // Only for logged in users
      // {
      //     path: 'sign-up',
      //     name: 'SignUp',
      //     component: () => import(/* webpackChunkName: 'sign-up' */ '@/views/userLoggedIn/SignUp.vue')
      // },
      //
      // {
      //     path: 'login',
      //     name: 'Login',
      //     component: () => import(/* webpackChunkName: 'login' */ '@/views/userLoggedIn/Login.vue')
      // },
      //
      //
      // //  routes for Admin logged in
      // {
      //     path: 'series-list',
      //     name: 'AdminSeriesList',
      //     component: () => import(/* webpackChunkName: 'series-list' */ '@/views/userLoggedIn/AdminSeriesList.vue')
      // },
      // {
      //     path: 'series-list/:brand/:serie',
      //     name: 'AdminSerie',
      //     component: () => import(/* webpackChunkName: 'serie' */ '@/views/userLoggedIn/AdminSerie.vue')
      // }

      //  External links
      // {
      //   path: 'koff-login',
      //   beforeEnter() {location.href = 'https://shop.koff.ro/login'}
      // },
      //
      // {
      //   path: 'koff-register',
      //   beforeEnter() {location.href = 'https://shop.koff.ro/'}
      // },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0 };
  },
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  let language = to.params.lang;

  if (!language) {
    i18n.locale = localStorage.getItem("language") || "ro";
    router.push(`/${i18n.locale}`);
  } else {
    let availableLang = process.env.VUE_APP_I18N_LOCALE;
    let languageExist = availableLang.split(",").includes(language);

    if (!languageExist) {
      i18n.locale = localStorage.getItem("language") || "ro";
      router.push(`/${i18n.locale}`);
    } else {
      i18n.locale = language;
    }
  }

  // Set html lang attribute
  document.querySelector("html").setAttribute("lang", language);

  // set dynamic title and meta description
  document.title = t("meta.title");
  document.querySelector('meta[property="og:title"]').content =
    t("meta.og-title");
  document.querySelector('meta[name="description"]').content =
    t("meta.description");
  document.querySelector('meta[property="og:description"]').content = t(
    "meta.og-description"
  );

  // START define dynamic country
  let dynamicCountry;
  switch (i18n.locale) {
    case "bg":
      dynamicCountry = "България";
      break;
    case "de":
      dynamicCountry = "Deutschland";
      break;
    case "en":
      dynamicCountry = "Europe";
      break;
    case "es":
      dynamicCountry = "España";
      break;
    case "fr":
      dynamicCountry = "France";
      break;
    case "it":
      dynamicCountry = "Italia";
      break;
    case "pl":
      dynamicCountry = "Polsce";
      break;
    case "ro":
      dynamicCountry = "Romania";
      break;
    case "nl":
      dynamicCountry = "Netherlands";
      break;
    case "sw":
      dynamicCountry = "Sweden";
      break;
    case "cz":
      dynamicCountry = "České republice";
      break;
    case "gr":
      dynamicCountry = "Ελλάδα";
      break;
    case "hr":
      dynamicCountry = "Hrvatskoj";
      break;
    case "pt":
      dynamicCountry = "Portugal";
      break;
    case "sk":
      dynamicCountry = "Slovakia";
      break;
    case "si":
      dynamicCountry = "Slovenia";
      break;
    default:
      dynamicCountry = "your country";
  }
  // END define country

  // dynamic brand name
  let dynamicBrand;
  if (to.params.brand) {
    dynamicBrand =
      to.params.brand.charAt(0).toUpperCase() + to.params.brand.slice(1);
  }

  // START Meta-description and Title
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle && nearestWithMeta.name === "Brands") {
    switch (i18n.locale) {
      case "bg":
        document.title = `Дистрибутор на едро ${dynamicBrand} в ${dynamicCountry} - KOFF`;
        break;
      case "de":
        document.title = `${dynamicBrand}-Großhändler in ${dynamicCountry} - KOFF`;
        break;
      case "en":
        document.title = `Wholesale ${dynamicBrand} distributor in ${dynamicCountry} - KOFF`;
        break;
      case "es":
        document.title = `Distribuidor mayorista de ${dynamicBrand} en ${dynamicCountry} - KOFF`;
        break;
      case "fr":
        document.title = `Distributeur grossiste ${dynamicBrand} en ${dynamicCountry} - KOFF`;
        break;
      case "it":
        document.title = `Distributore ${dynamicBrand} all'ingrosso in ${dynamicCountry} - KOFF`;
        break;
      case "pl":
        document.title = `Hurtowy dystrybutor ${dynamicBrand} w ${dynamicCountry} - KOFF`;
        break;
      case "ro":
        document.title = `Distribuitor engros ${dynamicBrand} in ${dynamicCountry} - Koff.ro`;
        break;
      case "nl":
        document.title = `${dynamicBrand} accessoires Groothandel in ${dynamicCountry} - KOFF`;
        break;
      case "sw":
        document.title = `Grossist ${dynamicBrand}-distributör i ${dynamicCountry} - KOFF`;
        break;
      case "cz":
        document.title = `Velkoobchodní distributor ${dynamicBrand} v ${dynamicCountry} - KOFF`;
        break;
      case "gr":
        document.title = `Χονδρικός διανομέας ${dynamicBrand} στην ${dynamicCountry} - KOFF`;
        break;
      case "hr":
        document.title = `Veleprodaja ${dynamicBrand} distributera u ${dynamicCountry} - KOFF`;
        break;
      case "pt":
        document.title = `Distribuidor grossista ${dynamicBrand} em ${dynamicCountry} - KOFF`;
        break;
      case "sk":
        document.title = `Veľkoobchodný distribútor ${dynamicBrand} na ${dynamicCountry} - KOFF`;
        break;
      case "si":
        document.title = `Veleprodajni distributer ${dynamicBrand} v ${dynamicCountry} - KOFF`;
        break;
      default:
        document.title = `Wholesale ${dynamicBrand} distributor in ${dynamicCountry} - KOFF`;
    }
  }
  // else if(previousNearestWithMeta) {
  //     document.title = previousNearestWithMeta.meta.title;
  // }

  // Remove any stale meta tags from the document using the key attribute we set below.
  // Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) {
    return next();
  } else {
    if (to.params.brand) {
      switch (i18n.locale) {
        case "bg":
          document.querySelector(
            'meta[name="description"]'
          ).content = `Аксесоарите на ${dynamicBrand}на цени на едро вече са налични в нашата B2B платформа за разпространение. Незабавна доставка от наличност до всяка точка на България.`;
          break;
        case "de":
          document.querySelector(
            'meta[name="description"]'
          ).content = `${dynamicBrand}-Zubehör zu Großhandelspreisen jetzt auf unserer B2B-Vertriebsplattform erhältlich. Deutschlandweit sofort ab Lager lieferbar.`;
          break;
        case "en":
          document.querySelector(
            'meta[name="description"]'
          ).content = `${dynamicBrand} accessories at wholesale prices now available on our B2B distribution platform. Immediate delivery from stock anywhere in Europe.`;
          break;
        case "gr":
          document.querySelector(
            'meta[name="description"]'
          ).content = `Αξεσουάρ ${dynamicBrand} σε τιμές χονδρικής τώρα διαθέσιμα στην πλατφόρμα διανομής μας B2B. Άμεση παράδοση από το απόθεμα οπουδήποτε στην Ελλάδα.`;
          break;
        case "es":
          document.querySelector(
            'meta[name="description"]'
          ).content = `Accesorios ${dynamicBrand} a precios de mayorista ya disponibles en nuestra plataforma de distribución B2B. Entrega inmediata desde stock en cualquier punto de España.`;
          break;
        case "fr":
          document.querySelector(
            'meta[name="description"]'
          ).content = `Les accessoires ${dynamicBrand} à prix de gros sont désormais disponibles sur notre plateforme de distribution B2B. Livraison immédiate de stock partout en France.`;
          break;
        case "it":
          document.querySelector(
            'meta[name="description"]'
          ).content = `Accessori ${dynamicBrand} a prezzi all'ingrosso ora disponibili sulla nostra piattaforma di distribuzione B2B. Spedizione immediata da magazzino in tutta Italia.`;
          break;
        case "pl":
          document.querySelector(
            'meta[name="description"]'
          ).content = `Akcesoria ${dynamicBrand} w cenach hurtowych od teraz dostępne na naszej platformie dystrybucyjnej B2B. Natychmiastowa dostawa z magazynu w dowolne miejsce w Polsce.`;
          break;
        case "nl":
          document.querySelector(
            'meta[name="description"]'
          ).content = `${dynamicBrand}-accessoires tegen groothandelsprijzen nu beschikbaar op ons B2B-distributieplatform. Directe levering uit voorraad overal in Europa.`;
          break;
        case "sw":
          document.querySelector(
            'meta[name="description"]'
          ).content = `${dynamicBrand}-tillbehör till grossistpriser nu tillgängliga på vår B2B-distributionsplattform. Omedelbar leverans från lager överallt i Sverige.`;
          break;
        case "si":
          document.querySelector(
            'meta[name="description"]'
          ).content = `Dodatki ${dynamicBrand} po veleprodajnih cenah so zdaj na voljo na naši distribucijski platformi B2B. Takojšnja dobava iz zaloge kjerkoli v Sloveniji.`;
          break;
        case "ro":
          document.querySelector(
            'meta[name="description"]'
          ).content = `Accesorii GSM ${dynamicBrand} la preturi en-gross, disponibile acum in platforma de distributie B2B Koff.ro - Livrare imediata din stoc oriunde in Romania.`;
      }
    }
  }

  // // Turn the meta tag definitions into actual elements in the head.
  // nearestWithMeta.meta.metaTags.map(tagDef => {
  //     const tag = document.createElement('meta');
  //
  //     Object.keys(tagDef).forEach(key => {
  //         tag.setAttribute(key, tagDef[key]);
  //     });
  //
  //     // We use this to track which meta tags we create so we don't interfere with other ones.
  //     tag.setAttribute('data-vue-router-controlled', '');
  //
  //     return tag;
  // })
  // // Add the meta tags to the document head.
  //     .forEach(tag => document.head.appendChild(tag));

  // END Meta-description and Title

  next();
});

export default router;
